/**
 * @name persistLocalStorage
 * @description This function saves the value of a key in the local storage.
 * @param {String} key - The key to save.
 * @param {Object} value - The value to save.
 *
 */

export const persistLocalStorage = (key, value) => {
	localStorage.setItem(key, JSON.stringify({ ...value }));
};

/**
 * @name clearLocalStorage
 * @description This function removes a key from the local storage.
 * @param {String} key - The key to remove.
 */

export const clearLocalStorage = key => {
	localStorage.removeItem(key);
};
