import { Text as ChakraText } from '@chakra-ui/react';
import { forwardRef } from 'react';

/**
 * @name TextBlue
 * @description Text variant in blue color
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

const TextBlue = forwardRef(function Text(props, ref) {
	const { children, ...rest } = props;

	return (
		<ChakraText
			ref={ref}
			{...rest}
			color={'base.default-500'}
			fontSize='text-small'
		>
			{children}
		</ChakraText>
	);
});

export default TextBlue;
