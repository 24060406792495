import rawTokens from '../../tokens/token.json';

/**
 * @name mapEffects
 * @description Map the primitive effects from the design tokens to a format that can be used by the system
 * @param {Object} effectJson The primitive effects from the design tokens
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

const mapEffects = effectJson => {
	const mappedEffects = { shadows: {} };

	if (!effectJson.shadow) return mappedEffects;

	const processValue = (key, value) => {
		if (Array.isArray(value)) {
			// Procesar si $value es un array
			mappedEffects.shadows[key] = value
				.map(convertShadowToBoxShadow)
				.join(', ');
		} else if (value) {
			// Procesar si $value es un objeto único
			mappedEffects.shadows[key] = convertShadowToBoxShadow(value);
		}
	};

	Object.entries(effectJson.shadow).forEach(([category, sizes]) => {
		Object.entries(sizes).forEach(([size, data]) => {
			const baseKey = `${category}.${size}`;

			if (data?.$value) {
				processValue(baseKey, data.$value);
			} else {
				// Procesar extensiones adicionales ("0", "1", etc.)
				Object.entries(data).forEach(([extension, extensionData]) => {
					const extendedKey = `${baseKey}.${extension}`;
					if (extensionData?.$value) {
						processValue(extendedKey, extensionData.$value);
					}
				});
			}
		});
	});

	return mappedEffects;
};

/**
 * @name convertShadowToBoxShadow
 * @description Convert the shadow object to a CSS box-shadow string
 * @param {Object} effect The shadow object
 * @returns {String} The shadow converted to a box-shadow string
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

const convertShadowToBoxShadow = effect => {
	const { type, x, y, blur, spread, color } = effect;

	if (type === 'innerShadow') {
		return `inset ${x}px ${y}px ${blur}px ${spread}px ${color}`;
	}

	return `${x}px ${y}px ${blur}px ${spread}px ${color}`;
};

const effects = mapEffects(rawTokens.Primitives);

// Export the effects ready to use
export default effects.shadows;
