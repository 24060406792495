import { Box as ChakraBox } from '@chakra-ui/react';
import { forwardRef } from 'react';

/**
 * @name Box
 * @description A customized Box component with default styles
 * @param {ReactNode} children - The children of the box
 *
 */

export const Box = forwardRef(function Box(props, ref) {
	const { children, ...rest } = props;

	return (
		<ChakraBox
			ref={ref}
			p='20px'
			w='fit-content'
			boxShadow='neutral.base'
			border='1px solid '
			borderColor={'layout.foreground-200'}
			borderRadius='rounded-large'
			{...rest}
		>
			{children}
		</ChakraBox>
	);
});
