import { Input } from '../../../components/ui/Input/input';
import { Box } from '../../../components/ui/box';

/**
 * @name LoginComponent
 * @description This component renders the login form.
 * @returns {React.ReactNode} - The login form
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

const LoginComponent = () => {
	return (
		<Box minW='545px'>
			<Input />
			<Input type='password' />
		</Box>
	);
};

export default LoginComponent;
