import { forwardRef } from 'react';
import {
	AbsoluteCenter,
	Button as ChakraButton,
	Span,
	Spinner,
} from '@chakra-ui/react';

/**
 * @name Button
 * @description A customized Button component with default styles - downloaded from Chakra UI
 * @param {ReactNode} children - The children of the button
 * @param {boolean} loading - The loading state of the button
 * @param {boolean} disabled - The disabled state of the button
 * @param {string} loadingText - The text to display when the button is loading
 * @param {React.Ref} ref - The reference of the button
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

export const Button = forwardRef(function Button(props, ref) {
	const { loading, disabled, loadingText, children, ...rest } = props;
	return (
		<ChakraButton
			disabled={loading || disabled}
			ref={ref}
			{...rest}
			bg='base.primary'
		>
			{loading && !loadingText ? (
				<>
					<AbsoluteCenter display='inline-flex'>
						<Spinner size='inherit' color='inherit' />
					</AbsoluteCenter>
					<Span opacity={0}>{children}</Span>
				</>
			) : loading && loadingText ? (
				<>
					<Spinner size='inherit' color='inherit' />
					{loadingText}
				</>
			) : (
				children
			)}
		</ChakraButton>
	);
});
