// Componente principal de rutas
import AppRoutes from './routes/AppRoutes';
import { Provider } from './components/ui/provider';
import { Global } from '@emotion/react';
import { Toaster } from './components/ui/toaster';

export const App = () => {
	return (
		<Provider>
			<Global
				styles={`@import url('https://fonts.cdnfonts.com/css/helvetica-neue-5');`}
			/>
			<Toaster />
			<AppRoutes />
		</Provider>
	);
};

export default App;
