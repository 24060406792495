import rawTokens from '../../tokens/token.json';

/**
 * @name mapColors
 * @description Map the primitive colors from the design tokens to a format that can be used by the system
 * @param {Object} colorsObj The primitive colors from the design tokens
 * @returns {Object} The colors converted to a format that can be used by the system
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

const mapColors = colorsObj => {
	const result = {};

	const traverse = (obj, path = []) => {
		for (const key in obj) {
			if (
				typeof obj[key] === 'object' &&
				obj[key] !== null &&
				!obj[key].$value
			) {
				// Si el valor es un objeto y no contiene $value, seguimos recorriendo
				traverse(obj[key], [...path, key]);
			} else if (obj[key]?.$value) {
				// Si encontramos un $value, agregamos al resultado con la ruta completa
				const fullPath = [...path, key].join('.');
				result[fullPath] = obj[key].$value;
			}
		}
	};

	traverse(colorsObj);
	return result;
};

const colors = mapColors(rawTokens.Primitives.colors.common);

// Export the colors ready to use
export default colors;
