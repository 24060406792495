import { createIcon } from '@chakra-ui/react';

const IconEyeSlash = createIcon({
	displayName: 'Eye',
	viewBox: '0 0 24 24',
	path: (
		<>
			<path stroke='none' d='M0 0h24v24H0z' fill='none' />
			<path
				d='M21.2699 9.18005C20.9799 8.72005 20.6699 8.29005 20.3499 7.89005C19.9799 7.42005 19.2799 7.38005 18.8599 7.80005L15.8599 10.8001C16.0799 11.4601 16.1199 12.2201 15.9199 13.0101C15.5699 14.4201 14.4299 15.5601 13.0199 15.9101C12.2299 16.1101 11.4699 16.0701 10.8099 15.8501C10.8099 15.8501 9.37995 17.2801 8.34995 18.3101C7.84995 18.8101 8.00995 19.6901 8.67995 19.9501C9.74995 20.3601 10.8599 20.5701 11.9999 20.5701C13.7799 20.5701 15.5099 20.0501 17.0899 19.0801C18.6999 18.0801 20.1499 16.6101 21.3199 14.74C22.2699 13.2301 22.2199 10.6901 21.2699 9.18005Z'
				fill='currentColor'
			/>
			<path
				d='M14.0199 9.97989L9.97989 14.0199C9.46989 13.4999 9.13989 12.7799 9.13989 11.9999C9.13989 10.4299 10.4199 9.13989 11.9999 9.13989C12.7799 9.13989 13.4999 9.46989 14.0199 9.97989Z'
				fill='currentColor'
			/>
			<path
				d='M18.25 5.74993L14.86 9.13993C14.13 8.39993 13.12 7.95993 12 7.95993C9.76 7.95993 7.96 9.76993 7.96 11.9999C7.96 13.1199 8.41 14.1299 9.14 14.8599L5.76 18.2499H5.75C4.64 17.3499 3.62 16.1999 2.75 14.8399C1.75 13.2699 1.75 10.7199 2.75 9.14993C3.91 7.32993 5.33 5.89993 6.91 4.91993C8.49 3.95993 10.22 3.42993 12 3.42993C14.23 3.42993 16.39 4.24993 18.25 5.74993Z'
				fill='currentColor'
			/>
			<path
				d='M14.8601 12.0001C14.8601 13.5701 13.5801 14.8601 12.0001 14.8601C11.9401 14.8601 11.8901 14.8601 11.8301 14.8401L14.8401 11.8301C14.8601 11.8901 14.8601 11.9401 14.8601 12.0001Z'
				fill='currentColor'
			/>
			<path
				d='M21.7699 2.22988C21.4699 1.92988 20.9799 1.92988 20.6799 2.22988L2.22988 20.6899C1.92988 20.9899 1.92988 21.4799 2.22988 21.7799C2.37988 21.9199 2.56988 21.9999 2.76988 21.9999C2.96988 21.9999 3.15988 21.9199 3.30988 21.7699L21.7699 3.30988C22.0799 3.00988 22.0799 2.52988 21.7699 2.22988Z'
				fill='currentColor'
			/>
		</>
	),
});

export default IconEyeSlash;
