import { transformUnits } from '../../services/themeServices';
import rawTokens from '../../tokens/design-tokens.tokens.json';

/**
 * @name mapUnits
 * @description Map the primitive units from the design tokens to a format that can be used by the system
 * @param {Object} units The primitive units from the design tokens
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

const mapUnits = units => {
	const result = {};
	for (const [unitName, unitData] of Object.entries(units)) {
		if (
			!unitData ||
			unitData.type !== 'dimension' ||
			unitData.value === undefined
		) {
			console.error(
				`La unidad "${unitName}" no tiene un formato válido.`,
				unitData,
			);
			continue; // Ignora esta entrada si está mal formateada
		}

		// Mapea el nombre y valor de la unidad
		result[unitName] = `${unitData.value}px`;
	}
	return transformUnits(result);
};

const units = mapUnits(rawTokens.primitives.units);

export default units;
