export const defaultInputProps = {
	bg: 'base.default-100',
	border: 'none',
	focusRing: 'none',
	placeholder: 'Introduce tu email',
	_placeholder: {
		color: 'base.default-300',
	},
	_hover: {
		bg: 'base.default-200',
	},
	transition: 'all 0.2s ease',
	borderRadius: 'rounded-small',
	// Con esto permites que cualquier prop extra pasada a MyComponent se aplique también
};
