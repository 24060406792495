/* eslint-disable react/display-name */
import { memo } from 'react';
import { Route } from 'react-router-dom';

import Layout from '../layouts/Layout.js';
import { PrivateRoutes, PublicRoutes } from './Routes.js';
import RoutesNotFound from './RoutesNotFound';
import AuthGuard from './guard/AuthGuard.js';
import Home from '../pages/Home/index.js';
import Login from '../pages/Login/index.js';
import Private from '../pages/Private/index.js';

const AppRoutes = memo(() => {
	return (
		<RoutesNotFound>
			{/* Public Routes */}
			{/* EJEMPLO: */}
			<Route
				path={`/${PublicRoutes.Home}`}
				element={<Layout screen={<Home />} />}
			/>
			<Route
				path={`/${PublicRoutes.Login}`}
				element={<Layout screen={<Login />} />}
			/>

			{/* Private Routes */}
			<Route element={<AuthGuard />}>
				{/* EJEMPLO: */}
				<Route path={PrivateRoutes.PrivateMenu} element={<Private />} />
			</Route>
		</RoutesNotFound>
	);
});

export default AppRoutes;
