import { Box } from '../../components/ui/box';
import { Button } from '../../components/ui/button';
import { Text } from '../../components/ui/Text';
import { promiseToast, toast } from '../../utils/Toasts';

const Home = () => {
	return (
		<>
			<Box>
				<Text.Gray>Esto es un Home</Text.Gray>
				<Button
					onClick={() =>
						toast(
							'info',
							'Éxito',
							'La operación se ha completado correctamente',
						)
					}
				>
					Test Toast
				</Button>
				<Button
					ml={3}
					onClick={() =>
						promiseToast(
							new Promise((resolve, reject) => {
								setTimeout(() => {
									resolve();
								}, 3000);
							}),
							'Procesando...',
							'Porfavor espere',
						)
					}
				>
					Test Promise Toast
				</Button>
			</Box>
		</>
	);
};
export default Home;
