import { createIcon } from '@chakra-ui/react';

const BaseIconCloseCircle = createIcon({
	displayName: 'IconCloseCircle',
	viewBox: '0 0 20 20',
	path: (
		<>
			<path
				d='M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z'
				fill='currentColor'
			/>
		</>
	),
});

export const IconCloseCircle = () => {
	return (
		<BaseIconCloseCircle
			color='base.default-500'
			cursor='pointer'
			_active={{ scale: 0.9 }}
			transition={'scale 0.1s'}
		/>
	);
};
