import {
	Box,
	HStack,
	IconButton,
	Input,
	Stack,
	mergeRefs,
	useControllableState,
} from '@chakra-ui/react';
import * as React from 'react';
import { InputGroup } from './input-group';
import IconEyeSlash from '../../assets/icons/IconEyeSlash';
import IconEye from '../../assets/icons/IconEye';
import { getColorPalette } from '../../utils/componentsUtils';

export const PasswordInput = React.forwardRef(
	function PasswordInput(props, ref) {
		const {
			rootProps,
			defaultVisible,
			visible: visibleProp,
			onVisibleChange,
			visibilityIcon = {
				on: <IconEye color='base.default-500' />,
				off: <IconEyeSlash color='base.default-500' />,
			},
			...rest
		} = props;

		const [visible, setVisible] = useControllableState({
			value: visibleProp,
			defaultValue: defaultVisible || false,
			onChange: onVisibleChange,
		});

		const inputRef = React.useRef(null);

		return (
			<InputGroup
				width='full'
				endElement={
					<VisibilityTrigger
						disabled={rest.disabled}
						onPointerDown={e => {
							if (rest.disabled) return;
							if (e.button !== 0) return;
							e.preventDefault();
							setVisible(!visible);
						}}
					>
						{visible ? visibilityIcon.off : visibilityIcon.on}
					</VisibilityTrigger>
				}
				{...rootProps}
			>
				<Input
					{...rest}
					ref={mergeRefs(ref, inputRef)}
					type={visible ? 'text' : 'password'}
				/>
			</InputGroup>
		);
	},
);

const VisibilityTrigger = React.forwardRef(
	function VisibilityTrigger(props, ref) {
		return (
			<IconButton
				tabIndex={-1}
				ref={ref}
				me='-2'
				aspectRatio='square'
				size='sm'
				variant='ghost'
				height='calc(100% - {spacing.2})'
				aria-label='Toggle password visibility'
				{...props}
			/>
		);
	},
);

export const PasswordStrengthMeter = React.forwardRef(
	function PasswordStrengthMeter(props, ref) {
		const { max = 4, value, ...rest } = props;

		const percent = (value / max) * 100;
		const { label, colorPalette } = getColorPalette(percent);

		return (
			<Stack align='flex-end' gap='1' ref={ref} {...rest}>
				<HStack width='full' ref={ref} {...rest}>
					{Array.from({ length: max }).map((_, index) => (
						<Box
							key={index}
							height='1'
							flex='1'
							rounded='sm'
							data-selected={index < value ? '' : undefined}
							layerStyle='fill.subtle'
							colorPalette='gray'
							_selected={{
								colorPalette,
								layerStyle: 'fill.solid',
							}}
						/>
					))}
				</HStack>
				{label && <HStack textStyle='xs'>{label}</HStack>}
			</Stack>
		);
	},
);
