import { MdMenuBook } from 'react-icons/md';

export const PublicRoutes = {
	Home: '/',
	Login: 'login',
};

export const PrivateRoutes = {
	PrivateMenu: 'private-menu',
};

export const menuLinks = [
	{
		title: 'Home',
		to: '/',
		icon: MdMenuBook,
	},
	{
		title: 'Login',
		to: `/${PublicRoutes.Login}`,
		icon: MdMenuBook,
	},
	{
		title: 'Private Menú',
		to: `/${PrivateRoutes.PrivateMenu}`,
		icon: MdMenuBook,
	},
];
