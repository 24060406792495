import { transformData } from '../../services/themeServices';
import token from '../../tokens/token.json';

/**
 * @name mapToken
 * @description Map the tokens from the design tokens to a format that can be used by the system
 * @param {Object} input The design tokens
 */

function mapToken(input) {
	const mapObject = obj => {
		const result = {};
		Object.entries(obj).forEach(([key, value]) => {
			if (value && typeof value === 'object' && !Array.isArray(value)) {
				// Si es un objeto, mapear recursivamente
				result[key] = mapObject(value);
			} else {
				// Si es un valor directo, asignarlo
				result[key] = value;
			}
		});
		return result;
	};

	return mapObject(input);
}

/**
 * @name mapToChakraTheme
 * @description Map the tokens to a format that can be used by Chakra UI
 * @param {Object} lightColors The light color tokens
 * @param {Object} darkColors The dark color tokens
 * @param {Object} tokens The design tokens
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

const mapToChakraTheme = (lightColors, darkColors, tokens) => {
	return {
		// Mapea los colores
		colors: {
			light: lightColors.colors,
			dark: darkColors.colors,
		},
		sizes: transformData(tokens.Primitives.units),
		radii: transformData(tokens.light.layout.radius), // Mapea bordes redondeados
		lineHeights: transformData(tokens.light.layout['line-height']), // Mapea alturas de línea
		fontSizes: transformData(tokens.light.layout['font-size']), // Mapea tamaños de fuente
		borders: transformData(tokens.light.layout['border-width']), // Mapea anchos de borde
		opacity: transformData(tokens.light.layout['disabled-opacity']), // Mapea opacidades
	};
};

// Map the tokens
const lightToken = mapToken(token.light);
const darkToken = mapToken(token.dark);
const normalizedToken = mapToChakraTheme(lightToken, darkToken, token);

// Export the token ready for use
export default normalizedToken;
