/**
*
    @name Login
    @description It is the Login page
    @returns {JS.Element} index.js
    @author Ángel González | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

import { Text } from '../../components/ui/Text';
import LoginComponent from './components/LoginComponent';

const Login = () => {
	return (
		<>
			<Text.Gray>Login Screen</Text.Gray>
			<LoginComponent />
		</>
	);
};

export default Login;
