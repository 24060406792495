import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// --------------------- Redux --------------------
import { Provider } from 'react-redux';
import { store } from './redux/store/Store';
// ------------------------------------------------

import App from './App';
// ------------------------------------------------

// ----------------- Cookie login -----------------
import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_API_ROUTE;
// ------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<Provider store={store}>
			<App />
		</Provider>
	</BrowserRouter>,
);
