import { Box, Stack } from '@chakra-ui/react';
import HeaderComponent from '../components/HeaderComponent';
import { Text } from '../components/ui/Text';

/**
 * @name Layout
 * @description This component is a layout that renders a page.
 * @param {React.ReactNode} screen - The page to render.
 * @returns {React.ReactNode} - The layout
 */

const Layout = ({ screen }) => {
	return (
		<Box
			bg='layout.background'
			p={8}
			minH='100vh'
			minW='100vw'
			color='black'
		>
			<Box py={3} bg='base.default'>
				<HeaderComponent />
			</Box>
			<Text.Gray>Esto es un layout</Text.Gray>
			<Text.Gray>El Layout renderiza una página:</Text.Gray>
			<Stack>{screen}</Stack>
		</Box>
	);
};
export default Layout;
