import { Group, InputElement } from '@chakra-ui/react';
import { cloneElement, forwardRef } from 'react';

/**
 * @name InputGroup
 * @description Input group component for the application - downloaded from chakra package
 * @param {object} props Component props
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

export const InputGroup = forwardRef(function InputGroup(props, ref) {
	const {
		startElement,
		startElementProps,
		endElement,
		endElementProps,
		children,
		startOffset = '6px',
		endOffset = '6px',
		...rest
	} = props;

	return (
		<Group ref={ref} {...rest}>
			{startElement && (
				<InputElement pointerEvents='none' {...startElementProps}>
					{startElement}
				</InputElement>
			)}
			{cloneElement(children, {
				...(startElement && {
					ps: `calc(var(--input-height) - ${startOffset})`,
				}),
				...(endElement && {
					pe: `calc(var(--input-height) - ${endOffset})`,
				}),
				...children.props,
			})}
			{endElement && (
				<InputElement placement='end' {...endElementProps}>
					{endElement}
				</InputElement>
			)}
		</Group>
	);
});
