import SpecificPrivateComponent from './components/SpecificPrivateComponent';

const Private = () => {
	return (
		<>
			<div>This is a Private Screen</div>
			<SpecificPrivateComponent />
		</>
	);
};
export default Private;
