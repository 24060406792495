/**
 * @name resolveColorReferences
 * @description Changes the color references to the correct format.
 * @param {Object} modeTokens The tokens of the primitive colors from the design tokens
 * @returns {Object} The colors converted to a format that can be used by the system
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

export const resolveColorReferences = modeTokens => {
	const transformedTokens = {};
	for (const [mode, tokens] of Object.entries(modeTokens)) {
		transformedTokens[mode] = transformTokens(tokens);
	}
	return transformedTokens;
};

/**
 * @name transformValue
 * @description Transforms the value of a token to the correct format only if the color is a reference.
 * @param {String} value The value of the token
 * @returns {String} The value converted to a format that can be used by the system
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

const transformValue = value => {
	if (
		typeof value === 'string' &&
		value.startsWith('{') &&
		value.endsWith('}')
	) {
		return value.replace('colors.common.', 'colors.');
	}

	return value;
};

/**
 * @name transformTokens
 * @description Transforms the tokens to the correct format.
 * @param {Object} tokens The tokens to be transformed
 * @returns {Object} The tokens converted to a format that can be used by the system
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

const transformTokens = tokens => {
	if (typeof tokens === 'string') {
		return transformValue(tokens);
	}

	if (Array.isArray(tokens)) {
		return tokens.map(transformTokens);
	}

	if (typeof tokens === 'object' && tokens !== null) {
		// Si el objeto tiene $type y $value, asumimos que es un token del estilo {$type: 'color', $value: '...'}
		if ('$type' in tokens && '$value' in tokens) {
			return transformValue(tokens.$value);
		}

		const transformedObject = {};
		for (const [key, val] of Object.entries(tokens)) {
			transformedObject[key] = transformTokens(val);
		}
		return transformedObject;
	}

	return tokens;
};

/**
 * @name transformResolvedColors
 * @description Transforms the resolved colors to the correct format.
 * @param {Object} resolvedColors The resolved colors to be transformed
 * @returns {Object} The resolved colors converted to a format that can be used by the system
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

export const transformResolvedColors = resolvedColors => {
	const { light = {}, dark = {} } = resolvedColors;

	const result = {};

	// Recursive function to flatten the object and map the values
	const flattenAndMap = (obj, prefix = '') => {
		for (const [key, val] of Object.entries(obj)) {
			const newKey = prefix ? `${prefix}.${key}` : key;

			if (
				typeof val === 'object' &&
				val !== null &&
				!Array.isArray(val)
			) {
				// Si es un objeto, seguimos aplanando recursivamente
				flattenAndMap(val, newKey);
			} else {
				// Si es un valor (string u otro tipo primitivo):
				// Buscamos el valor correspondiente en dark usando la misma ruta.
				const darkVal = getNestedValue(dark, newKey.split('.'));
				result[newKey] = {
					value: {
						base: val,
						// Si existe su contraparte en dark la usamos, si no, devolvemos el mismo valor que en default.
						_dark: darkVal ?? val,
					},
				};
			}
		}
	};
	// Función auxiliar para obtener un valor de "dark" dada una ruta
	const getNestedValue = (obj, path) => {
		return path.reduce(
			(acc, curr) =>
				acc && acc[curr] !== undefined ? acc[curr] : undefined,
			obj,
		);
	};

	flattenAndMap(light);
	return result;
};

/**
 * @name transformData
 * @description Transforms the data to the correct format.
 * @param {Object} borderRadiusObj The data to be transformed
 * @returns {Object} The data converted to a format that can be used by the system
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

export const transformData = borderRadiusObj => {
	const transformed = {};
	for (const [key, val] of Object.entries(borderRadiusObj)) {
		transformed[key] = { value: `${val.$value}px` };
	}
	return transformed;
};

/**
 * @name transformUnits
 * @description Transforms the units to the correct format.
 * @param {Object} unitsObj The units to be transformed
 * @returns {Object} The units converted to a format that can be used by the system
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

export const transformUnits = unitsObj => {
	const result = {};
	for (const [key, value] of Object.entries(unitsObj)) {
		result[key] = { value };
	}
	return result;
};
