import { toaster } from '../components/ui/toaster';
/**
 * @name toast
 * @description This function creates a success toast.
 * @param {Function} toast - The toast function from Chakra UI.
 * @param {String} title - The title of the toast.
 * @param {String} message - The message of the toast.
 */
export const toast = (type, title, description) => {
	toaster.create({
		title,
		description,
		type,
		overlap: true,
		duration: 3000,
		meta: { closable: true },
	});
};

/**
 * @name promiseToast
 * @description This function creates a promise toast.
 * @param {Promise} promise - The promise to be executed.
 * @param {String} title - The title of the toast.
 * @param {String} description - The description of the toast.
 */

export const promiseToast = (promise, title, description) => {
	toaster.promise(promise, {
		loading: {
			title,
			description,
		},
		success: {
			title: 'Éxito',
			description: 'La operación se ha completado correctamente',
			duration: 5000,
		},
		error: {
			title: 'Error',
			description: 'Ha ocurrido un error, intentelos de nuevo más tarde',
			duration: 5000,
		},
	});
};
