import { ClientOnly, IconButton, Skeleton } from '@chakra-ui/react';
import { ThemeProvider, useTheme } from 'next-themes';
import { forwardRef } from 'react';
import { LuMoon, LuSun } from 'react-icons/lu';

export function ColorModeProvider(props) {
	return (
		<ThemeProvider attribute='class' disableTransitionOnChange {...props} />
	);
}

export function useColorMode() {
	const { resolvedTheme, setTheme } = useTheme();
	const toggleColorMode = () => {
		setTheme(resolvedTheme === 'light' ? 'dark' : 'light');
	};
	return {
		colorMode: resolvedTheme,
		setColorMode: setTheme,
		toggleColorMode,
	};
}

export function useColorModeValue(light, dark) {
	const { colorMode } = useColorMode();
	return colorMode === 'light' ? light : dark;
}

export function ColorModeIcon() {
	const { colorMode } = useColorMode();
	return colorMode === 'light' ? <LuSun /> : <LuMoon />;
}

export const ColorModeButton = forwardRef(function ColorModeButton(props, ref) {
	const { toggleColorMode } = useColorMode();
	return (
		<ClientOnly fallback={<Skeleton boxSize='8' />}>
			<IconButton
				onClick={toggleColorMode}
				variant='ghost'
				aria-label='Toggle color mode'
				size='sm'
				ref={ref}
				{...props}
				css={{
					_icon: {
						width: '5',
						height: '5',
					},
				}}
			>
				<ColorModeIcon />
			</IconButton>
		</ClientOnly>
	);
});
