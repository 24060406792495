import { forwardRef } from 'react';
import { Input as ChakraInput, Stack } from '@chakra-ui/react';
import { defaultInputProps } from './defaultProps';
import { Text } from '../Text';
import { PasswordInput } from '../password-input';

export const Input = forwardRef(function Input(props, ref) {
	const { type, label, ...rest } = props;

	return (
		<Stack>
			<Text.Gray>{label}</Text.Gray>
			{type !== 'password' ? (
				<ChakraInput ref={ref} {...rest} {...defaultInputProps} />
			) : (
				<PasswordInput ref={ref} {...rest} {...defaultInputProps} />
			)}
		</Stack>
	);
});
