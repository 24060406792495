import rawTokens from '../../tokens/design-tokens.tokens.json';

/**
 * @name mapFonts
 * @description Map the primitive fonts from the design tokens to a format that can be used by the system
 * @param {Object} fonts The primitive fonts from the design tokens
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

const mapFonts = fonts => {
	const result = {};
	for (const [fontName, fontData] of Object.entries(fonts)) {
		const { value } = fontData;

		result[fontName] = {
			value: {
				fontSize: `${value.fontSize}px`,
				textDecoration: value.textDecoration,
				fontFamily: value.fontFamily,
				fontWeight: value.fontWeight,
				fontStyle: value.fontStyle,
				letterSpacing: `${value.letterSpacing}px`,
				lineHeight: `${value.lineHeight}px`,
				textCase: value.textCase, // Si no hay transformación, la omitimos
			},
		};
	}
	return result;
};

const textStyles = mapFonts(rawTokens.font);

// Export the token ready for use
export default textStyles;
