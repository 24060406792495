// Extensiones
import { createSystem, defaultConfig } from '@chakra-ui/react';

import { colors, textStyles, tokens, effects, config } from './extensions';
import {
	resolveColorReferences,
	transformResolvedColors,
} from '../services/themeServices';

// Resolves color references and transforms the resolved colors into a format that can be used by the system
const resolvedColors = resolveColorReferences(
	{
		light: tokens.colors.light,
		dark: tokens.colors.dark,
	},
	colors,
);

// Normalizes the resolved colors
const normalizedColors = transformResolvedColors(resolvedColors);

// Creates the system
export const system = createSystem(defaultConfig, {
	theme: {
		tokens: {
			...tokens,

			colors,
			shadows: effects,
		},
		semanticTokens: {
			colors: {
				...normalizedColors,
			},
		},
		config,
		textStyles,
	},

	shadows: effects.shadows,
});
