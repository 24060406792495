/**
 * @name TextHeader
 * @description Text variant for headers
 * @param {string} variant - The variant of the gray color
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

import { Text } from '.';

const TextHeader = props => {
	const { children, variant, ...rest } = props;
	return (
		<Text.Gray {...rest} variant={variant} textStyle='title-xlarge'>
			{children}
		</Text.Gray>
	);
};

export default TextHeader;
