import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PublicRoutes } from '../Routes';

export const AuthGuard = () => {
	const userState = useSelector(store => store.user);

	// TODO: Verificar con email?
	return userState?.email?.length > 0 ? (
		<Outlet />
	) : (
		<Navigate replace to={PublicRoutes.Home} />
	);
};

export default AuthGuard;
