import { Link } from 'react-router-dom';
import { Stack, Text } from '@chakra-ui/react';
import { menuLinks } from '../routes/Routes';
import { ColorModeButton } from './ui/color-mode';

/**
 * @name HeaderComponent
 * @description Header component for the application
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

const HeaderComponent = () => {
	return (
		<Stack flexDir='row'>
			{menuLinks.map(({ title, to }, index) => (
				<Link key={index} id={index} to={to}>
					<Text
						fontWeight='bold'
						fontSize='14px'
						textDecor='underline'
					>
						{title}
					</Text>
				</Link>
			))}
			<ColorModeButton />
		</Stack>
	);
};
export default HeaderComponent;
