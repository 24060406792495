import { Text as ChakraText } from '@chakra-ui/react';
import { forwardRef } from 'react';

const grayVariants = {
	100: 'base.default-100',
	200: 'base.default-200',
	300: 'base.default-300',
	400: 'base.default-400',
	500: 'base.default-500',
	600: 'base.default-600',
	700: 'base.default-700',
	800: 'base.default-800',
	900: 'base.default-900',
};

/**
 * @name TextGray
 * @description Text variant in gray color
 * @param {string} variant - The variant of the gray color
 * @param {string} textStyle - The text style of the text
 * @author Eduardo Ruiz | Unknown Gravity - All-in-one Blockchain Company
 */

const TextGray = forwardRef(function Text(props, ref) {
	const { children, variant, textStyle, ...rest } = props;
	const color = grayVariants[variant] || 'base.default-600';
	return (
		<ChakraText
			ref={ref}
			{...rest}
			color={color}
			textStyle={textStyle || 'text-medium'}
		>
			{children}
		</ChakraText>
	);
});

export default TextGray;
